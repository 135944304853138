import React from 'react';
import Main from './components/Main';
import './App.css';


function App() {
  return (
    <div className="">
     <Main />
    </div>
  );
}

export default App;
