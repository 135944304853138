import ImageGallery from "react-image-gallery";
import one from '../assets/first.png'
import two from '../assets/second.png'
import three from '../assets/third.png'
// import fourth from '../assets/t-shirt-1.png'
import sphtee5 from '../assets/sph1.png'
// import sphtee3 from '../assets/sphtee3.png'
// import sphtee5 from '../assets/t-shirt-front.png'
import audio1 from '../assets/audio.mp3'

import React, { useState, useRef, Component } from 'react';
import { AudioPlayer } from 'react-audio-player-component';
import ReactPlayer from 'react-player'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

let images = [
    {
      original: one,
      thumbnail: one,
    },
    {
      original: two,
      thumbnail: two,
    },
    {
      original: three,
      thumbnail: three,
    },
    {
      original: sphtee5,
      thumbnail: sphtee5,
    }
  ];

// images = images.sort(() => Math.random() - 0.5)

const Main = () => {
  
  const [open, setOpen] = useState(false);
  // const closeIcon = (
  //   <svg fill="currentColor" viewBox="0 0 20 20" width={28} height={28}>
  //     <path
  //       fillRule="evenodd"
  //       d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
  //       clipRule="evenodd"
  //     ></path>
  //   </svg>
  // );


  // const onOpenModal = () => setOpen(true);
  // const onCloseModal = () => setOpen(false);
  
  return (
    
    <div className='bg-amber-300 text-center text-base md:text-lg h-[100dvh] overflow-hidden'>
      
      <div className="">
        <ImageGallery 
          items={images} 
          autoPlay={true} 
          showThumbnails={false}
          showPlayButton={false}
          showNav={false}
          slideDuration={2000}
          slideInterval={5000}
          onClick={() => setOpen(true)}
        
        />
    
      </div>

      <div className="">
        <h1 className="text-xl content-center font-bold">@SMALLSPARADISEHARLEM</h1> 
        <p className="">7% Alc. | Made to Invoke an Elegance of Luxury</p> 
    
        <center className="bg-amber-300 pt-1">
          <AudioPlayer 
            src={audio1}
            minimal={true}
            width={250}
            trackHeight={35}
            barWidth={2}
            gap={2}
            loop
            autoplay="auto"

            visualise={true}
            backgroundColor="#fcd34d"
            barColor="#C1D0B5"
            barPlayedColor="#fff"

            skipDuration={2}
            showLoopOption={true}
            showVolumeControl={true}
          

            seekBarColor="purple"
            // volumeControlColor="blue"
            hideSeekBar={true}
            // hideTrackKnobWhenPlaying={true}
            additionalClass="text-lg react-player"
          />

          <p className="bg-amber-300">
            Play | Pause Music | <span className="bg-sky-300 hover:bg-sky-700">
              <a href= "mailto: smallparadiseharlem@gmail.com"> Tap to email us</a>
            </span>
            
          </p>
        </center>

      <Modal 
        open={open}
        onClose={() => setOpen(false)}
        center
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModal',
        }}
        centeraria-labelledby="my-modal-title"
        aria-describedby="my-modal-description"
        
      >
        

        <p id="my-modal-title" className="text-center	text-black">Smalls Paradise | Harlem Mixtape</p>
        <p id="my-modal-description" className="text-center mb-1 text-black">Artist Profile: Gritlok</p>
        <div id="wrapper">
        <ReactPlayer 
          url="https://vimeo.com/952870210/7b0c46a840"

          // url="https://drive.google.com/file/d/1Ptqe3R_9gBF5erV4cmGOW59K2767m0zt/view?usp=sharing"
          
          playing={true} 
          controls={true}
          autoPlay={true}
          height="auto"
          width="auto"
          
        
          
        />
        </div>
      </Modal> 
        
  
      </div>
    
    </div>
  )

  }
  
  export default Main